import React from "react";

export function PaginationList({ numOrders, pagination, setPagination }) {
  return (
    <nav
      className="flex items-center justify-between  bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <span className="font-medium">{pagination.page}</span> di{" "}
          <span className="font-medium">
            {roundUp((numOrders + 1) / pagination.rowsPage)}
          </span>{" "}
          | <span className="font-medium">Visualizza</span>{" "}
          <RowsPerPage pagination={pagination} setPagination={setPagination} />{" "}
          <span className="font-medium"> di {numOrders} risultati</span>
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <a
          onClick={() => setPagination({ ...pagination, page: pagination.page == 1 ? 1 : (pagination.page -= 1)})}
          className="cursor-pointer relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Indietro
        </a>
        <a
          onClick={() => setPagination({ ...pagination, page: (pagination.page += 1) })}
          className="cursor-pointer relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Avanti
        </a>
      </div>
    </nav>
  );
}

function roundUp(floatNum) {
  if (floatNum % 1 !== 0) {
    // Se la parte decimale non è zero
    return Math.ceil(floatNum); // Arrotonda per eccesso
  } else {
    return floatNum; // Altrimenti, il numero è già intero, quindi restituisci lo stesso numero
  }
}


const rowsPerPage = [
    20, 50, 100, 200
]

function RowsPerPage({ pagination, setPagination }) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
        {rowsPerPage.map((rows, index) => (
            <button
                key={rows}
                onClick={() => setPagination({ ...pagination, rowsPage: rows })}
                type="button"
                className={`relative inline-flex items-center ${index == 0 ? "rounded-l-md" : index == (rowsPerPage.length - 1) ? "rounded-r-md" : ""}  ${
                pagination.rowsPage == rows
                    ? "bg-green-500 hover:bg-green-500 text-white ring-green-500"
                    : "bg-white hover:bg-gray-50 text-gray-900 ring-gray-300"
                }  px-3 py-2 text-sm font-semibold  ring-1 ring-inset   focus:z-10`}
            >
                {rows}
            </button>
        ))}
    </span>
  );
}

