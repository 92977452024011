import React from "react";

function TutorialPage() {
  return (
    <main className="mt-4 flex gap-2 flex-wrap px-4">
      <div className="bg-gray-100 py-2 px-3 rounded-md max-w-96">
        <iframe
          className="rounded-md"
          width="100%"
          height="auto"
          src="https://www.youtube.com/embed/JeszOBah2os?si=pjjy0DQXTuo5mnD2"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>{" "}
        <h2 className="text-xl mt-2 uppercase underline text-center ">
          Come Utilizzare il Calcolatore dei Margini per Offerte Multiple
        </h2>
      </div>
      <div className="bg-gray-100 py-2 px-3 rounded-md max-w-96">
        <iframe
          className="rounded-md"
          width="100%"
          height="auto"
          src="https://www.youtube.com/embed/RM1yubIzRBA"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>{" "}
        <h2 className="text-xl mt-2 uppercase underline text-center">
          Come Collegare la Piattaforma CodFacile a Shopify
        </h2>
      </div>
      <div className="bg-gray-100 py-2 px-3 rounded-md max-w-96">
        <iframe
          className="rounded-md"
          width="100%"
          height="auto"
          src="https://www.youtube.com/embed/N-Zg8ORgaRY"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>{" "}
        <div className="text-xl mt-2 uppercase underline text-center ">
          Come Evadere Gli Ordini con la Piattaforma Codfacile
        </div>
      </div>
    </main>
  );
}

export default TutorialPage;
