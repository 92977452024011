import { userApi } from "./userApi";

const orderSelectedToPayApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    // GET: Ottieni tutti i record di recharge_balance
    getAllOrderSelectedToPay: builder.query({
      query: () => `order-selected-to-pay/get-all`,
      providesTags: ["OrderSelectedToPay"],
    }),

    // POST: Crea un nuovo record di recharge_balance
    addOrderSelectedToPay: builder.mutation({
      query: (orderId) => ({
        url: `order-selected-to-pay/add-one/${orderId}`,
        method: "POST",
      }),
      invalidatesTags: ["OrderSelectedToPay"], // Invalidiamo il tag per aggiornare la cache
    }),

    // PUT: Aggiorna un record esistente di recharge_balance
    updateOrderSelectedToPay: builder.mutation({
      query: ({ orderId, isSelected }) => ({
        url: `order-selected-to-pay/update-one/`,
        method: "PUT",
        body: { orderId, isSelected },
      }),
      invalidatesTags: ["OrderSelectedToPay"], // Invalidiamo il tag per aggiornare la cache
    }),

    // DELETE: Elimina un record di recharge_balance
    deleteOneOrderSelectedToPay: builder.mutation({
      query: (orderId) => ({
        url: `order-selected-to-pay/delete-one/${orderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OrderSelectedToPay"], // Invalidiamo il tag per aggiornare la cache
    }),
    // DELETE: Elimina un record di recharge_balance
    deleteAllOrderSelectedToPayByUserId: builder.mutation({
      query: () => ({
        url: `order-selected-to-pay/delete-all-by-user`,
        method: "DELETE",
      }),
      invalidatesTags: ["OrderSelectedToPay"], // Invalidiamo il tag per aggiornare la cache
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllOrderSelectedToPayQuery,
  useAddOrderSelectedToPayMutation,
  useUpdateOrderSelectedToPayMutation,
  useDeleteOneOrderSelectedToPayMutation,
  useDeleteAllOrderSelectedToPayByUserIdMutation,
} = orderSelectedToPayApi;
