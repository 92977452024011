import React, { useEffect } from "react";
import PopupDialog from "./custom/PopupDialog";
import { LoadingIcon } from "./custom/LoadingIcon";
import { CurrencyEuroIcon } from "@heroicons/react/outline";
import { DownloadIcon } from "@heroicons/react/solid";
import { useDeleteAllOrderSelectedToPayByUserIdMutation, useGetAllOrderSelectedToPayQuery, useUpdateOrderSelectedToPayMutation } from "../services/orderSelectedToPayApi";
import { usePayOrdersInBulkMutation } from "../services/stripeApi";

function PopupSelectedOrderToPay({ open, setOpen }) {

  const { data: ordersSelectedToPayDb, isLoading: gettingOrderToPay, isSuccess: gettedOrderToPay, isError: errorGettingOrderToPay, error: gettingOrderToPayError } = useGetAllOrderSelectedToPayQuery()
  const [updateOrderSelectedToPay, { isLoading: updatingOrderToPay, isSuccess: updatedOrderToPay, isError: isErrorUpdatingOrderToPay, error: errorUpdatingOrderToPay }] = useUpdateOrderSelectedToPayMutation()
  const [deleteAllOrderSelectedToPay, { isLoading: deletingAllOrderToPay, isSuccess: deletedAllOrderToPay, isError: isErrorDeletingAllOrderToPay, error: errorDeletingAllOrderToPay }] = useDeleteAllOrderSelectedToPayByUserIdMutation()
  const [payOrdersInBulk, { data: sessionPaymentInBulk, isLoading: payingOrdersInBulk, isSuccess: paidOrdersInBulk, isError: isErrorPayingOrdersInBulk, error: errorPayingOrdersInBulk }] = usePayOrdersInBulkMutation()

  const onSelectedChange = async (orderId, isSelected) => {
    await updateOrderSelectedToPay({ orderId, isSelected });
  }

  const onClickSvuota = async () => {
    await deleteAllOrderSelectedToPay();
  }

  var totalToPay = ordersSelectedToPayDb?.filter((o) => o.isSelected == true).reduce(
    (accumulator, order) => {
      return (
        accumulator + (order?.totalAmount ?? 0)
      );
    },
    0
  )

  const onClickPayAll = async () => {
    await payOrdersInBulk(ordersSelectedToPayDb.filter((order) => order.isSelected == true))
  }

  useEffect(() => {
    if(paidOrdersInBulk){
      window.location.href = sessionPaymentInBulk
    }
  }, [payingOrdersInBulk])

  return (
    <PopupDialog open={open} setOpen={setOpen} padding={"p-0"}>
      <section className="px-4 pb-4 pt-5 sm:p-6">
        {(ordersSelectedToPayDb?.length ?? 0) == 0 ? <h4 className="mb-4">Non ci sono ordini da pagare selezionati</h4> : <></>}
        {ordersSelectedToPayDb?.map((order) => (
          <div key={order.id} className="group shadow-md bg-white px-4 py-5 sm:px-6 mb-2 rounded-md hover:bg-gray-50 " onClick={() => onSelectedChange(order.id, !order.isSelected)}>
            <section className="flex gap-2">
              <div className="flex gap-5 items-center">
                <input type="checkbox" checked={order.isSelected} onChange={(e) => onSelectedChange(order.id, e.target.checked)}/> 
                <h3 className="text-base font-semibold text-gray-900">ORDINE N.{order.orderNumber}</h3>
              </div>
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Spedizione {order.shippingPriceCustomer}€
              </span>
              <span className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                Tot. {order.totalAmount.toFixed(2)}€
              </span>
            </section>
          <p className="mt-1 text-sm text-gray-500">
            {order.store}
          </p>
          {order?.products?.map((product) => (
          <div key={`${order.id} ${product.sku}`} className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex flex-wrap items-center gap-5 group-hover:bg-gray-50">
              <div className="flex flex-col">
                <h3 className="text-base font-semibold text-gray-900">{product.name}</h3>
                {(product?.fromInventory ?? 0) > 0 ? <span className="text-sm text-gray-500">{product.fromInventory} {product.fromInventory > 1 ? "presi" : "preso"} da inventario</span> : <></>}
              </div>
              <p className="mt-1 text-sm text-gray-500 flex gap-2 items-center">
                <span>{product.sku}</span>
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {product.user_price}€
                </span>
                <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                  x{product.quantity}
                </span>
              </p>
          </div>
          ))}
        </div>
      ))}
      </section>
      <section id="buttons" className="w-full flex justify-between sticky bottom-0 left-0 bg-white px-4 py-2 ">
        <button
          onClick={() => onClickSvuota()}
          type="button"
          className={
            "bg-gradient-to-r bg-gray-200 text-gray-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none inline-flex items-center px-5 py-2.5 border border-transparent text-sm  font-medium rounded-md shadow-sm  focus:ring-offset-2 "
          }
        >
          {deletingAllOrderToPay ? <LoadingIcon color={"text-gray-700"} /> : "Svuota"}
        </button>
        <button
          onClick={() => onClickPayAll()}
          type="button"
          className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
        >
          Paga
          {payingOrdersInBulk ? (
            <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
          ) : (
            <span className="flex gap-1 items-center">{totalToPay?.toFixed(2) ?? 0}
            <CurrencyEuroIcon className="h-4 w-4" />
            </span>
          )}
        </button>
      </section>
    </PopupDialog>
  );
}

export default PopupSelectedOrderToPay;
