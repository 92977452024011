import { userApi } from "./userApi";

const catalogApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    synchronizeCatalog: builder.mutation({
      query: () => ({ url: `catalog/sync`, method: "POST" }),
      invalidatesTags: ["ShopifyOrders", "Shipments"],
    }),
    uploadFileFetch: builder.mutation({
      query: (formData) => {
        return {
          url: "catalog/product/upload-image",
          method: "POST",
          formData: true,
          body: formData,
        };
      },
      invalidatesTags: ["ShopifyOrders", "Shipments"],
    }),
    updateProductPriceList: builder.mutation({
      query: ({ companyId, product }) => {
        return {
          url: `catalog/update-products-price-list/${companyId}`,
          method: "PUT",
          formData: true,
          body: { product },
        };
      },
      invalidatesTags: ["ShopifyOrders", "Shipments"],
    }),
    getProductsCatalog: builder.query({
      query: ({ searchText, page, rowsPage }) => `catalog/get-all-products?searchText=${searchText}&page=${page}&rowsPage=${rowsPage}`,
      providesTags: ["Catalog"],
    }),
    getOneProductById: builder.query({
      query: (productCatalogId) => `catalog/get-by-id/${productCatalogId}`,
    }),
    getCustomerProductsCatalog: builder.mutation({
      query: ({ searchText, companyId }) => ({
        url: `catalog/get-all-products/${companyId}`,
        method: "POST",
        body: { searchText },
      }),
    }),
    editProductCatalog: builder.mutation({
      query: (product) => ({
        url: `catalog/edit-product`,
        method: "PUT",
        body: { product },
      }),
    }),

    addProductCatalogImage: builder.mutation({
      query: (productCatalogImage) => ({
        url: `catalog/add-product-image`,
        method: "POST",
        body: productCatalogImage,
      }),
    }),


    deleteProductCatalogImage: builder.mutation({
      query: (productCatalogImageId) => ({
        url: `catalog/delete-product-image/${productCatalogImageId}`,
        method: "DELETE",
      }),
    }),

    moveProductCatalogImage: builder.mutation({
      query: ({ productCatalogImages }) => ({
        url: `catalog/move-product-image`,
        method: "PUT",
        body: { productCatalogImages }
      }),
    }),

    addProductCatalog: builder.mutation({
      query: (product) => ({
        url: `catalog/add-product`,
        method: "POST",
        body: { product },
      }),
    }),
    deleteProductCatalog: builder.mutation({
      query: (id) => ({
        url: `catalog/delete-product/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Catalog"],
    }),
    getProductCatalogById: builder.mutation({
      query: (id) => ({
        url: `catalog/get-by-id/${id}`,
        method: "GET",
      })
    }),

    getProductsPriceList: builder.query({
      query: () => `catalog/get-products-price-list`,
      providesTags: ["ProductsPriceList"],
    }),
    addProductsPriceList: builder.mutation({
      query: (productsPriceList) => ({
        url: `catalog/add-products-price-list`,
        method: "POST",
        body: productsPriceList,
      }),
      invalidatesTags: [
        "ShopifyOrders",
        "Shipments",
        "ProductsPriceList",
        "ShippingPriceList",
      ],
    }),
    editProductsPriceList: builder.mutation({
      query: (productsPriceListEdited) => ({
        url: `catalog/edit-products-price-list`,
        method: "PUT",
        body: productsPriceListEdited,
      }),
      invalidatesTags: [
        "ShopifyOrders",
        "Shipments",
        "ProductsPriceList",
        "ShippingPriceList",
      ],
    }),
    deleteProductsPriceList: builder.mutation({
      query: (id) => ({
        url: `catalog/delete-products-price-list/${id}`,
        method: "delete",
      }),
      invalidatesTags: [
        "ShopifyOrders",
        "Shipments",
        "ProductsPriceList",
        "ShippingPriceList",
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useSynchronizeCatalogMutation,
  useGetProductsPriceListQuery,
  useAddProductsPriceListMutation,
  useEditProductsPriceListMutation,
  useDeleteProductsPriceListMutation,
  useGetCustomerProductsCatalogMutation,
  useLazyGetProductsCatalogQuery,
  useGetProductsCatalogQuery,
  useAddProductCatalogMutation,
  useEditProductCatalogMutation,
  useDeleteProductCatalogMutation,
  useUploadFileFetchMutation,
  useUpdateProductPriceListMutation,
  useGetOneProductByIdQuery,
  useLazyGetOneProductByIdQuery,
  useAddProductCatalogImageMutation,
  useGetProductCatalogByIdMutation,
  useDeleteProductCatalogImageMutation,
  useMoveProductCatalogImageMutation
} = catalogApi;
