import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextCopyToClipboard from "../catalog/TextCopyToClipboard";
import { imagesUrl } from "../../config/baseUrl";
import { TrackingButton } from "../order/TrackingButton";

function CustomerProductBox({ product }) {
  var auth = useSelector((state) => state.auth);

  return (
    <div
      //key={product.id}
      className="relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
    >
      <div className="flex justify-center bg-white aspect-h-4 aspect-w-4 sm:aspect-none sm:h-96 relative">
        <img
          src={`${imagesUrl}${product?.productImage ?? ""}`}
          className="object-cover object-center h-full sm:h-full"
        />
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-xl font-medium text-gray-900 flex gap-2 flex-wrap items-center justify-between">
          <a>{product.productName}</a>
          <span>{product?.fullName ?? ""}</span>
        </h3>
        <TextCopyToClipboard
          text={product.productSku}
          textHover={"COPIA SKU"}
        />
        <div className="flex gap-1 items-center w-full text-base font-medium text-gray-900">
          <div className="flex items-center gap-2 w-full">
            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {`${product.qty} in magazzino`}
              </span>
            {product.paidToShip > 0 ? 
              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                  {`di cui ${product.paidToShip} ${product.paidToShip > 1 ? "pagati" : "pagato"} da spedire`}
              </span> : <></>}
          </div>
        </div>

        {/* <div className="flex flex-1 flex-col justify-end">
          <div className="flex gap-1 items-center w-full text-base font-medium text-gray-900">
            <div className="flex-col w-full">
              <span className="text-xl">{`${product.shipmentProducts.userCost}€`}</span>
            </div>
            <TrackingButton
              trackingNumber={product.shipmentProducts.shipment.trackingNumber}
              text={product.shipmentProducts.shipment.trackingNumber}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default CustomerProductBox;
